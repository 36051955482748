@import "~antd/dist/antd.less";

@import url("https://fonts.googleapis.com/css2?family=Allura&family=Caveat:wght@400;700&display=swap");

:root {
    --col-01 : #2ec2ca;
}

body {
    background-color: #f0f5f5;
}

.dishinDish {
    -webkit-box-shadow: inset 1px 1px 20px 0px rgba(46,194,202,0.1);
    -moz-box-shadow: inset 1px 1px 20px 0px rgba(46,194,202,0.1);
    box-shadow: inset 1px 1px 20px 0px rgba(46,194,202,0.1);
    padding: 1.6em;
    border: 1px solid rgba(46,194,202,0.2);
}

.emptyty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-drawer-header {
    position: relative;
    padding: 16px 24px;
    color: var(--col-01);
    background: #fff;
    border-bottom: 1px solid var(--col-01);

    .ant-drawer-title{
        color: var(--col-01);
    }
}

.cardContent {
    p {
        margin: 0;
    }
}

.dishTitleInCard {
     color: var(--col-01);
     font-size: 1.8em;
}

.dishPriceInCard {
    font-weight: 500;
    color: #FFFFFF;
    background-color: var(--col-01);
    padding: 10px;
    text-align: center;
    width: 5vw;
    border-radius: 4px;
}

.dishImageInCard {
    border-radius: 4%;
    position: relative; 
    z-index: 10; 
    object-fit: cover; 
    width: 10vw; 
    height: 10vw
}

.allerTitleInDishInCard {
    font-weight: 500;
}

.allerInDishInCard {
    font-size: 0.8em;
    color: #888888;
}

.catTitleInCard {
    margin: 1em 0 0 0;
}

.dishDescInCard {
    font-style: italic;
}

.spanInCard {
    color: var(--col-01);
}

.buttsInDishCatsEdit {
    margin: 0 0 0 4px;
}

.ant-card-actions {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    border-top: 1px solid #f0f0f0;
    border-bottom: 0;
}

.ant-collapse-content-box {
    padding: 0 !important;
}

.ant-select-multiple {
    background-color: #F0F5F5;
}

.ant-select-multiple .ant-select-selection-item {
    background-color: #FFFFFF;
}

.ant-card-body {
    padding: 0 20px!important;
}

.ant-page-header-heading-left {
    display: flex;
    align-items: center;
    margin: 0;
    overflow: hidden;
}

.flag {
    width: 16px;
    height: 11px !important;
    margin-right: 4px;
}

.dishTitle {
    font-size: 1.1em;
    font-weight: 500;
    color: var(--col-01);
}

.drinkTitle {
    font-size: 1.1em;
    font-weight: 500;
    color: var(--col-01);
    padding: 20px !important;
}

.wineTitle {
    font-size: 1.1em;
    font-weight: 500;
    color: var(--col-01);
    padding: 20px !important;
}

.cardTitle {
    font-size: 1.1em;
    font-weight: 500;
    color: var(--col-01);
}

.dishPrice {
    font-size: 1.1em;
    font-weight: 500;
    color: var(--col-01);
}

.ant-collapse-arrow {
    color: var(--col-01) !important;
}

.dishPrice {
    font-size: 1.1em;
    font-weight: 500;
    text-align: right;
    padding: 0;
    margin: 0;
    width: 80px !important;
}

.dishPrice span input {
    padding: 0 5px 0 0 !important;
    color: var(--col-01);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    margin: 18px 20px 0 20px;
    /* margin-top: -1px; */
    margin-bottom: 0;
    padding: 0 20px 5px 20px;
    padding-right: 0;
    padding-left: 0;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--col-01);
    font-weight: normal;
}

.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
    border-color: var(--col-01);
}

.ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
    flex: auto;
    padding: 0 8px;
    color: var(--col-01) !important;
}

.ant-space.ant-space-vertical
    > div:nth-child(1)
    > div
    > div
    > span
    > div.ant-upload-list.ant-upload-list-picture
    > div
    > div.ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture
    > div
    > span
    > span.ant-upload-list-item-name {
    color: var(--col-01) !important;
}

.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
    color: var(--col-01);
}

.ant-image {
    cursor: zoom-in;
}

.restoDetailsDescription {
    background-color: #f0f5f5 !important;
}

textarea.restoDetailsDescription {
    padding: 10px !important;
}

.ant-row > .ant-col > div > div {
    padding-top: 0;
    width: 100%;
    background-color: white;
}

.ant-space {
    width: inherit;
    background-color: white;
}

.ant-space-item {
    width: inherit;
    background-color: white;
}

.restoDetailsTopMargin {
    padding-top: 0;
}

.ant-input-group-addon {
    padding: 0;
    border: none;
    color: var(--col-01);
    background-color: white;
    padding: 0 10px 0 0;
}

.restoDetailsSocials {
    padding: 5px !important;
}

.ant-input-group .ant-input {
    padding: 0;
    font-weight: 500;
    border: none !important;
    outline: none;
}

.ant-input-borderless {
    padding: 0 !important;
}

.ant-card-head-title {
    color: var(--col-01);
}

.restoDetailsTitle {
    font-size: 1.6rem !important;
    font-weight: 500;
    color: rgb(0, 0, 0);
}

.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
}

.tableLink {
    font-weight: 500;
}

a.logo {
    width: 200px;
    height: 100%;
    background-position-x: left;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: 40%;
    background-color: rgb(255, 255, 255);
    background-image: url(./logo.svg);
    display: block;
}

.topTitle {
    color: #565656;
    margin: 0;
    font-family: "Allura", cursive;
    font-size: 2.2rem;
    font-weight: 400;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
}

#components-layout-demo-top-side .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

.site-layout-background {
    background: #fff;
}

.ant-menu-item {
    font-weight: 500;
}

.burger {
    display: none;
}

//////////////////////// NEW STYLES //////
//////////////////////// NEW STYLES //////
//////////////////////// NEW STYLES //////
//////////////////////// NEW STYLES //////

@media screen and (max-width: 740px) {

    body {
        background-color: white;
    }

    .ant-layout {
        background-color: white;
    }

    .layout-login {
        padding: 10vh 0px !important;
        background-color: white;
    }

    .login {
        img {
            width: 60%;
        }
        label.ant-form-item-required {
            font-size: 1rem;
            margin: 0;
        }

    }

    .login .ant-btn-primary {
        margin: 2vh 0 0 0;
        width: 100%;
        height: 5vh;
    }

    .burger {
        font-size: 2rem;
        color: var(--col-01);
        margin: 24px 24px;
        display: block;
    }

    .in-esta-00 {
        width: 100%;
    }

    .in-gestion-logo-00 {

        button,
        .ant-upload  {
            width: 100%;
        }

    }

    .ant-layout-content {
        margin: 0vh 0px 0px !important;
        padding: 7vh 2vw !important;
    }

    ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
        border: 0;
    }

    .ant-menu-item a {
        font-size: 1.4rem;
    }

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
        margin: 18px 20px 0 20px;
        margin-bottom: 0;
        padding: 0 20px 5px 20px;
    }

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
        margin: 5px 24px 10px 20px;
    }

    aside.ant-layout-sider.ant-layout-sider-dark.site-layout-background {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        min-width: 75vw !important;
        display: block;
        position: fixed;
        z-index: 1;
        top: 10vh;
        left: -100%;
        transition: 850ms;
        height: 100vh;
        border-top: none;
        border-right: 1px solid rgb(240,240,240);
    }

    aside.ant-layout-sider.ant-layout-sider-dark.site-layout-background.active {
        left: 0;
        transition: 350ms;
    }
    
    .ant-layout-header {
        height: 10vh !important;
        padding: 0 !important;
        width: 100vw !important;
        border-bottom: 1px solid #eee;

        a.logo {
            width: 30vw;
            height: 100%;
            background-position-x: center;
            background-position-y: center;
            background-repeat: no-repeat;
            background-size: 70%;
            background-color: rgb(255, 255, 255);
            background-image: url(./logo.svg);
            display: block;
        }

        h1 {
            display: none;
        }

        .ant-menu {
            line-height: 7vh;
        }

    }

    .ant-menu-inline {
        width: 100%;
        padding-top: 5vh;
    }

    .ant-table-content > table {
        table-layout:auto !important
    }

    .restoTable {

        th:nth-child(3), 
        th:nth-child(4),
        th:nth-child(5) {
            display: none
        }

        td:nth-child(3), 
        td:nth-child(4), 
        td:nth-child(5) {
            display: none
        }
    }

    .userTable {
        
        th:nth-child(1),
        th:nth-child(2),
        th:nth-child(3),
        th:nth-child(6) {
            display: none
        }

        td:nth-child(1),
        td:nth-child(2),
        td:nth-child(3), 
        td:nth-child(6) {
            display: none
        }
    }

    .ant-row {
        width: 100% !important;
        margin: 0 !important;

        .ant-col.ant-col-8 {
            max-width: 100% !important;
            padding: 0 !important;
            flex: 0 0 auto;

            .ant-card-cover {
                margin: 0 !important;
                padding: 0 !important;
                .ant-image {
                    img {
                        display: block;
                        position: relative;
                        z-index: 0 !important;
                        object-fit: cover;
                        // height: 20vh !important;
                        width: 100% !important;
                        border-radius: 0% !important;
                    }
                }
            }
        }

        .ant-col-16 {
            display: block;
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0 !important;

            .ant-card {
                .ant-card-body {
                    padding: 0 !important;
                }
            }
        }

    }

    .restoDetailsTitle {
        font-size: 1.6rem !important;
        font-weight: 500;
        color: var(--col-01);
    }

    // les cartes

    .in-card-00 {

        .in-card-01 {
            width: 80%;
        }

        .in-card-02 {
            width: 20%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;

                button {
                    margin-right: 0px !important;
                    margin-bottom: 1vh;
                }
        }

    }

    .in-card-details-00 {

        .ant-card-body {
            padding: 0 !important;
        }

    }

    .in-menu-details-00 { // <<<<< ranger dans les menus

        .ant-card-body {
            padding: 0 !important;
        }

    }

    .in-dish-00 {
        

        > div {
            flex: 1 0 0 !important;
        }


    }

    // les menus

    .in-menus-02
     {
        flex-direction: column;
        width: 20%;

        .ant-space-item {
            margin: 0 !important;

            input.input-prix {
                width: 46x;
                text-align: end;
                font-size: 0.5rem;
                margin: 0 0 1vh 0 !important;
            }

            .bout-duplicate {
                margin: 0 0 1vh 0 !important;
            }

        }
    }

    // les plats

    .invisible {
        display: none;
    }

    .in-dishes-00 {
        display: block !important;
        .ant-col-18 {
            max-width: 100% !important;
        }
        margin-bottom: 16px !important;
    }

    .in-dishes-01 {
        display: block !important;
        .ant-col-12 {
            max-width: 100% !important;
        }
        margin-bottom: 16px !important;
    }

    .dish {
        // background-color: red;
        margin-bottom: 1vh;
    }

    // les vins

    .in-wine-01 {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        div:nth-child(1) {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;
        }

        div:nth-child(2) {
            flex-grow: 6;
            flex-shrink: 6;
            flex-basis: 0;
        }

        div:nth-child(3) {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;
        }
    }

    .in-wine-02 {
        flex-wrap: wrap;
        width: 100%;

         > div:nth-child(2) {
            margin-top: 1vh;
        }
    }

    .in-wine-03 {
        flex-wrap: wrap;
        width: 100%;

        div:nth-child(3) div:nth-child(1) {
            flex: 0 6 auto;
        }

        div:nth-child(3) div ~ div:nth-child(2) {
            flex: 0 1 auto;
        }
    }

    .in-wine-04 {
        flex-wrap: wrap;
        width: 100%;
    }

    // les boissons

    .in-drink-01 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

        div:nth-child(1) {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;
        }

        div:nth-child(2) {
            flex-grow: 6;
            flex-shrink: 6;
            flex-basis: 0;
        }

        div:nth-child(3) {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;
        }
    }

    .in-drink-02 {
        flex-wrap: wrap;
        width: 100%;

        > div:nth-child(2) {
            margin-top: 1vh;
        }

    }


    .buttons-top button{
        margin: 0px 4px 16px 0px !important
    }



}
@primary-color: #2ec2ca;@link-color: #2ec2ca;@heading-color: rgba(0, 0, 0, 0.85);